@font-face {
  font-family: 'SpriteGraffitiShadow';
  src: local('SpriteGraffitiShadow'), url(./assets/FontsFree-Net-SpriteGraffitiShadow.ttf) format('truetype');
}
@font-face {
  font-family: 'ArchitectsDaughter';
  src: local('ArchitectsDaughter'), url(./assets/ArchitectsDaughter-Regular.ttf) format('truetype');
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

*{
  scroll-behavior: smooth;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  background-color: black;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
